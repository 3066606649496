import {ActivateCommand, LoginCommand, LoginResponse, ResetPasswordCommand} from "./AuthApiTypes";
import {useMutation} from "react-query";
import {authClient} from "../apiClients";

export const useLogin = () =>
    useMutation<LoginResponse, unknown, LoginCommand>('login', (command: LoginCommand) =>
            authClient.post(`authorization/login`, command)
                .then((data) => data.data),
    );

export const useReset = () =>
    useMutation<unknown, unknown, ResetPasswordCommand>('reset-password', (command: ResetPasswordCommand) =>
        authClient.post(`authorization/reset-password`, command)
            .then((data) => data.data),
    );

export const useActivate = () =>
    useMutation<unknown, unknown, ActivateCommand>('activate', (command: ActivateCommand) =>
        authClient.post(`authorization/activate`, command)
            .then((data) => data.data),
    );