import React from "react";
import {useActivate} from "../providers/auth/AuthApi";
import {useParams} from "react-router-dom";

const Activate = () => {
    const {token} = useParams();
    const activate = useActivate();
    const mainText = activate.isError ? "Ups!" : "Success!";
    const description = activate.isError ? "Something went wrong." : "Your account has been activated.";
    // @ts-ignore
    const smallText = activate.isError ? activate.error?.response?.data?.message + "." : "You can now login to your account.";

    React.useEffect(() => {
        if (token) {
            activate.mutate({token});
        }
    }, []);

    return (
        <div className="bg-white">
            <div className="relative isolate pt-14">
                <svg
                    aria-hidden="true"
                    className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                >
                    <defs>
                        <pattern
                            x="50%"
                            y={-1}
                            id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                            width={200}
                            height={200}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none"/>
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0}/>
                </svg>
                <div
                    className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                        <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                            {mainText} <br/> {description}
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            {smallText}
                        </p>
                        <div className="mt-10 flex items-center gap-x-6">
                            <a
                                href="/"
                                className="rounded-md bg-metcon px-3.5 py-2.5 text-sm font-semibold text-gray-800 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Go to homepage
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activate;