import {createBrowserRouter} from "react-router-dom";
import Home from "../views/Home";
import React from "react";
import Activate from "../views/Activate";
import Reset from "../views/Reset";

export const router = createBrowserRouter([
    {
        path: "/",
        element: (<Home />),
    },
    {
        path: "activate/:token",
        element: (<Activate />),
    },
    {
        path: "reset/:token",
        element: (<Reset />),
    },
]);