import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShieldDog} from "@fortawesome/pro-thin-svg-icons/faShieldDog";
import {useFormik} from "formik";
import {AlertType, useAlert} from "../providers/alertProvider";
import {useReset} from "../providers/auth/AuthApi";
import {ResetPasswordCommand} from "../providers/auth/AuthApiTypes";
import {useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";

const Reset = () => {
    const navigate = useNavigate();
    const {token} = useParams();
    const {showAlert} = useAlert();
    const reset = useReset();

    const validationSchema = yup.object({
        password: yup
            .string()
            .required("Field required"),
        retypedPassword: yup
            .string()
            .oneOf([yup.ref('password')], "Passwords must match"),
    });

    const initialValues: ResetPasswordCommand = {
        password: "",
        retypedPassword: "",
        token: `${token}`,
    };

    const onSubmit = (values: ResetPasswordCommand) => {
        reset.mutateAsync(values)
            .then(() => {
                navigate('/')
                showAlert("Password reset", "Your password has been reset", AlertType.SUCCESS)
            })
            .catch((err: any) => {
                showAlert("Problem with reset", err?.response?.data?.message, AlertType.DANGER);
            })
    };

    const formik = useFormik({
        initialValues, onSubmit,
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
    })

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto flex content-center flex-col sm:w-full sm:max-w-sm">
                    {/*@ts-ignore*/}
                    <FontAwesomeIcon size={'4x'} color={'#fcab4c'} icon={faShieldDog} />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Reset your password
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                New Password
                            </label>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    required
                                    autoComplete="current-password"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Retype Password
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="retypedPassword"
                                    name="retypedPassword"
                                    type="password"
                                    onChange={formik.handleChange}
                                    value={formik.values.retypedPassword}
                                    required
                                    autoComplete="current-password"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                onClick={() => formik.handleSubmit()}
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-metcon px-3 py-1.5 text-sm font-semibold leading-6 text-gray-800 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Reset password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reset;